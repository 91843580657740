{
  "view-details": "View Details",
  "call-center": {
    "queue": "Queue",
    "direct-call": "Direct call",
    "no-logged-in-agents": "There are currently no agents logged in this queue.",
    "agents-in-queue":"Agents in this queue",
    "tier": "Tier",
    "todays-calls": "Today´s calls",
    "agent-activity": {
      "logged-out": "Logged Out",
      "log-in": "Log In",
      "logged-in": "Logged In",
      "available": "Available",
      "break": "On Break",
      "in-call": "In Call",
      "in-wrapup": "In Wrapup",
      "wrapup": "Wrapup"
    },
    "call-analytics": {
      "waiting": "Waiting",
      "in-call": "In Call",
      "handled": "Handled",
      "abandoned": "Abandoned"
    }
  },
  "contact-details": {
    "email": "E-Mail",
    "title": "Contact details",
    "location": "Location",
    "address": "Address",
    "postcode": "Postcode"
  },
  "integration-settings": {
    "title": "Azure Integration Settings",
    "description": "By entering the data, you automatically activate the Azure integration, which includes MS Teams presence synchronization and contacts synchronization.",
    "label": "Integration",
    "AzureSettings": "Microsoft Azure Integration",
    "azure-client-id": "Application (client) ID",
    "azure-client-secret": "Client secret value",
    "azure-tenant-id": "Directory (tenant) ID",
    "delete-config-msg": "Do you really want to delete the Azure configuration?",
    "configuration": "Azure configuration"
  },
  "admin-user-form": {
    "select-user": "Please select a user",
    "user-doesnt-exist": "This user does not exist",
    "successfully-created": "User was successfully created.",
    "successfully-updated": "User was successfully updated.",
    "validation-error": "There were errors in your form. Please fix them and try again."
  },
  "invalid-uuid": "The value should be in the UUID format",
  "invalid-data": "Invalid data provided. Please review and try again",
  "add": "Add",
  "yes-delete": "Yes, delete",
  "add-user": "Add user",
  "closewindowdespitecall": "There is still an active call, close window anyways?",
  "admin-portal": "Admin Portal",
  "internal": "Internal",
  "external": "External",
  "inbound": "Inbound",
  "search": "Search",
  "no": "No",
  "unsavedchanges": {
    "validmessage": "Unsaved changes",
    "invalidmessage": "Unsaved invalid changes",
    "valid-content": "There are some unsaved changes. Would you like to discard them before leaving?",
    "invalid-content": "There are some unsaved invalid changes. Would you like to revert them before leaving?",
    "content": "There are some unsaved changes. Would you like to revert them before leaving?",
    "revert": "Discard Changes",
    "cancel": "Adjust input",
    "save": "Save"
  },
  "loading": "Loading …",
  "agentspace": "Agent Panel",
  "set-agent-status": "Set agent's status",
  "choose-agent-status": "Please choose agent's status.",
  "set-status": "Set status",
  "agents": "Agents",
  "agent": "Agent",
  "members": "Incoming calls (Queue)",
  "queue": "Queue",
  "agent-status": {
    "all": "All",
    "in-call": "In Call",
    "logged-out": "Logged Out",
    "logged-in": "Logged In",
    "available": "Available",
    "break": "On Break",
    "wrap-up": "Wrap-up",
    "inactive": "Inactive"
  },
  "homeview": {
    "no-missed-calls": "There are no new missed calls.",
    "up-to-date": "You're up to date."
  },
  "unknown": "Unknown",
  "post-processing-time": "Post-processing time",
  "call-reason": "Call reason",
  "call-history": "Caller history",
  "caller": "Caller",
  "call_history": "Call history",
  "queues-waiting": "Queue waiting",
  "waiting": "Waiting",
  "request_supervisor": "Request supervisor",
  "requests_supervisor": "Supervisor requests",
  "supervisor": "Supervisor",
  "referred-by": "Referred by",
  "diverted-by": "Forwarded by",
  "name": "Name",
  "status": "Status",
  "all": "View all",
  "number": "Number",
  "position": "Position",
  "waiting-time": "Waiting time",
  "steady": "Steady",
  "close": "Close",
  "calls": "Calls",
  "replayed": "Replayed",
  "canceled": "Canceled",
  "service-level": "Service-level",
  "call-detail": "Call detail",
  "phone": "Phone",
  "offline_message": "You are logged out",
  "break_message": "You are on break",
  "no_agent": "You aren't assigned to an agent",
  "forward_to": "Forward to",
  "connect_to": "Connect to",
  "cancel": "Cancel",
  "more": "More",
  "merge": "Merge",
  "warm": "Warm",
  "cold": "Cold",
  "hang-up": "Hang up",
  "parking": "Parking",
  "warmtransfer": "Warm transfer",
  "coldtransfer": "Cold transfer",
  "hold": "Hold",
  "speaker": "Speaker",
  "unhold": "Resume",
  "mute": "Mute",
  "addparticipant": "Add participant",
  "soundoutputsettings": "Sound output settings",
  "participant": "Participant",
  "keypad": "Keypad",
  "dialpad": "Dial",
  "endcall": "Leave",
  "insertnameornumber": "Enter name or number",
  "group": "Group",
  "declined": "Declined",
  "timeout": "Timeout",
  "busy": "Busy",
  "add-contact-number": "Enter contact, number",
  "conference-member": "Conference participants",
  "back": "Back",
  "failed": "Unreachable",
  "hung-up": "Hung up",
  "sound-error": "Microphone error",
  "save": "Save",
  "extension": "Extension",
  "phonelabel": {
    "mobile": "Mobile",
    "fax": "Fax",
    "work": "Work",
    "other": "Other"
  },
  "addresslabel": {
    "work": "Work"
  },
  "video": "Video",
  "work": "Work",
  "favorites": "Favorites",
  "allContacts": "All contacts",
  "favoriteAdd": "Add to favorites",
  "favoriteRemove": "Remove from favorites",
  "importedFrom": "Imported from",
  "AssistantTelephoneNumber": "Assistant",
  "BusinessTelephoneNumber": "Business",
  "Business2TelephoneNumber": "Business 2",
  "BusinessFaxNumber": "Business Fax",
  "CallbackTelephoneNumber": "Callback",
  "CarTelephoneNumber": "Car",
  "company": "Company",
  "HomeTelephoneNumber": "Home",
  "Home2TelephoneNumber": "Home 2",
  "HomeFaxNumber": "Home Fax",
  "ISDNNumber": "ISDE",
  "MobileTelephoneNumber": "Mobile",
  "OtherFaxNumber": "Other Fax",
  "OtherTelephoneNumber": "Other",
  "PrimaryTelephoneNumber": "Primary",
  "RadioTelephoneNumber": "Radio",
  "TelexNumber": "Telex",
  "TTYTDDTelephoneNumber": "TTY/TDD",
  "home": "Home",
  "statistics": "Statistics",
  "hourabbrev": "hr",
  "minuteabbrev": "min",
  "secondabbrev": "s",
  "totallogintime": "Total login time",
  "averagecallduration": "Average call duration",
  "acceptedcalls": "Accepted calls",
  "missedcalls": "Missed calls",
  "nomissedcalls": "You didn't miss anything.",
  "languages": "Languages",
  "language": "Language",
  "logIn": "Login",
  "password": "Password",
  "stayLoggedIn": "Remember Me",
  "forgotPassword": "Forgot your password?",
  "userLogin": "User",
  "autologinfailed": "Automatic login failed",
  "unknownversion": "Unknown version",
  "userneedsdomain": "Error: Please enter a domain in the username field.",
  "userhasnoextension": "Error: There is no extension assigned to the user.",
  "wrongcredentials": "Error: The username and password don't match.",
  "inactiveUser": "Error: User is not active.",
  "serverunreachable": "Error: Can't reach the server at {0}.",
  "checkwtgcloud": "Did you mean wtg.cloud instead of wtg.com?",
  "unknownerror": "Unknown error: {0}",
  "mobiletitel": "Phone",
  "history": "History",
  "numpad": "Numpad",
  "recordings": "Recordings",
  "voicemails": "Voicemails",
  "historytabs": {
    "all": "All",
    "missed": "Missed",
    "inbound": "Inbound",
    "outbound": "Outbound"
  },
  "enternameornumber": "Enter number",
  "internalextensionnumber": "Extension",
  "externalextensionnumber": "My number",
  "request-password-reset-header": "Forgot password?",
  "user-at-dot": "Username has to contain an {'@'} and a .",
  "reset-password": "Reset Password",
  "return-to-login": "Back to login",
  "passwordreset-success-msg": "An email has been sent to your address to reset your password. Please check your inbox.",
  "not-working": "Unfortunately there was an error.",
  "error403": "You probably tried to reset Your password too many times at once.",
  "error400": "Please check Your username and try again.",
  "error-domain": "Please check your domain (the part after '{'@'}').",
  "resetsuccesstitle": "Password was reset",
  "resetsuccessmessage": "Your password has been reset.",
  "tologin": "To Login",
  "resetpassword": "Reset password",
  "newpassword": "New password",
  "passwordhastocontain8chars": "The password has to be at least 8 characters long.",
  "repeatpassword": "Repeat password",
  "passwordsdontmatch": "The passwords don't match.",
  "savepassword": "Save password",
  "settings": "Settings",
  "user": {
    "deletePhotoConfirmationTitle": "Profile picture",
    "deletePhotoConfirmationMessage": "Do you really want to delete the picture?",
    "userprofile": "Profile",
    "profilepicture": "Profile Picture",
    "newprofilepicture": "New Profile Picture",
    "deleteprofilepicture": "Delete Profile Picture",
    "uploadprofilepicture": "Upload",
    "changepassword": "Change Password",
    "oldpassword": "Old Password",
    "newpassword": "New Password",
    "repeatnewpassword": "Repeat Password",
    "extension": "Extension",
    "extensionpassword": "Extension Password",
    "language": "Language",
    "passwordwaschanged": "Password changed.",
    "useatleast8characters": "The password has to be at least 8 characters long.",
    "passwordsdontmatch": "Passwords don't match",
    "passwordchangeerror": "That did not work. Please check your old password and try again.",
    "languages": {
      "de": "German",
      "en": "English",
      "fr": "French",
      "cs": "Czech"
    },
    "disablePhone": "Disable telephony in the softclient"
  },
  "callforward": {
    "callforward": "Call Forward",
    "target": "Target",
    "all": "Forward All",
    "alldescription": "Forward all incoming calls to the target.",
    "busy": "On Busy",
    "busydescription": "The extension mailbox will be overridden.",
    "noanswer": "No answer",
    "noanswerdescription": "The extension mailbox will be overridden.",
    "notregistered": "Not registered",
    "notregistereddescription": "Forward call to new target if the extension is not reachable."
  },
  "followme": {
    "deleteTargetConfirmationTitle": "Follow me",
    "deleteTargetConfirmationMessage": "Do you really want to delete the target?",
    "followme": "Follow Me",
    "target": "Target",
    "delay": "Delay",
    "timeout": "Timeout",
    "prompt": "Prompt",
    "delete": "Delete",
    "prompton": "On",
    "promptoff": "Off",
    "adddestination": "Add",
    "ignorebusy": "Ignore Busy",
    "ignorebusydescription": "End call if a target is busy."
  },
  "extensionsettings": {
    "extensionsettings": "Extension Settings",
    "busyonbusydecision": "Multiple incoming calls",
    "busyonbusy": "Busy on Busy",
    "allowcallwaiting": "Allow call waiting",
    "secretary-settings": "Secretary settings",
    "active": "Active",
    "secretary": "Secretary",
    "exceptions": "Exceptions",
    "callGroups": "Call groups"
  },
  "audio": {
    "audio": "Audio",
    "inputs": "Input",
    "outputs": "Output",
    "canteditoutputs": "Audio outputs can't be edited in this browser.",
    "ringingoutput": "Ringtone Output",
    "internalRingtone": "Internal call ringtone",
    "externalRingtone": "External call ringtone",
    "ringonboth": "Ring on both devices",
    "defaultoutput": "Default (Running calls won't be affected)",
    "defaultinput": "Default"
  },
  "notifications": {
    "notifications": "Notification",
    "pickup": "Pickup",
    "banner": "Banner",
    "banner_info": "Display notification banner when a call is received in the Pickup group",
    "sound_status": "Sound",
    "sound_status_info": "Play sound when a call is received in the Pickup group"
  },
  "information": {
    "information": "Client Information",
    "clientversion": "Client Version",
    "showchangelog": "Show full change log"
  },
  "callscript": {
    "callscript": "Call script",
    "selectcallscript": "Select call script",
    "removecallscript": "Remove call script",
    "testcallscript": "Test call script"
  },
  "hotkey": {
    "hotkey": "Hotkey",
    "movetoforeground": "Move to foreground",
    "directcall": "Call immediately",
    "keybidingPermissions": "Enable keybinding permissions"
  },
  "contacts": {
    "contacts": "Contacts",
    "outlook": "Outlook Contacts",
    "importOutlookContacts": "Import Outlook Contacts",
    "refreshOutlookContacts": "Refresh Outlook Contacts",
    "removeOutlookContacts": "Remove Outlook contacts",
    "status": {
      "success": "Contacts imported successfully.",
      "failure": "Error while importing contacts.",
      "running": "Importing contacts …"
    },
    "currentlyImported": "Number of imported contacts",
    "outlookExplanation": "Contacts that are saved in outlook on this computer can be imported. The imported contacts remain on this computer and are not available from other devices."
  },
  "logs": {
    "logs": "Logs",
    "send-logs": "Send logs",
    "delete-logs": "Delete logs",
    "note": "Add a note to the logs",
    "disclaimer": "Logs are only saved locally and are deleted when closing the client or browser.\nThey can be sent to the WTG by clicking 'Send logs'.",
    "success": "Sent logs successfully.",
    "failure": "Error while sending logs.",
    "running": "Sending logs …",
    "deleted": "Logs deleted.",
    "oldest-log": "Oldest log"
  },
  "revert": "Revert changes",
  "invalidphonenumber": "Invalid phone number.",
  "section_header_second": "CONTACT",
  "my_favorites": "My favorites",
  "all_contacts": "All contacts",
  "logging_out_message": "Logging out",
  "logging_in_message": "Logging in",
  "inbound-calls": "Inbound calls",
  "parked-calls": "Parked calls",
  "park_call": "Park call",
  "notes": "Notes",
  "add_notes": "Attached note",
  "incomingcall": "Incoming call",
  "fromconference": "from a conference with {0} participants",
  "accept": "Accept",
  "reject": "Reject",
  "statusType": {
    "available": "Available",
    "busy": "Busy",
    "dnd": "Do not disturb",
    "away": "Be right back",
    "hidden": "Show as offline"
  },
  "target": "Target",
  "logout": "Log out",
  "changelog": "Change log",
  "currentversion": "Current version",
  "previousversion": "Previous version",
  "queues": "Queues",
  "switchboard": "Switchboard",
  "help": "Help",
  "title": "My status",
  "label-idle": "On Break",
  "label-active": "Logged In",
  "notLoggedOut": "All logged in",
  "wait-time": "Waiting time",
  "myActiveQueues": "My active queues",
  "noActiveQueues": "No active queues",
  "active": "Active",
  "onbreak": "On Break",
  "inactive": "Inactive",
  "waitingMemberCount": "Call queue",
  "holding": "Holding",
  "dialing": "Dialing",
  "user-cant-be-edited": "User can't be edited here",
  "contact-wtg": "Please contact the WTG.",
  "error": {
    "TOO_MANY_CONTACTS": "Too many associated contacts",
    "TOO_MANY_EXTENSIONS": "Too many associated extensions",
    "TOO_MANY_VOICEMAILS": "Too many associated voicemails",
    "TOO_MANY_DESTINATIONS": "Too many inbound numbers",
    "wronglink": "This password reset link isn't correct. Please close this tab and click on the link in your mail again.",
    "tokeninvalid": "Your password reset expired. Please request a new one using the function on the login page.",
    "unknown": "That didn't work, please try again.",
    "call-history-loading-error": "There was an error while loading the call history."
  },
  "newuser": "New user",
  "errorKey": "Error",
  "enabled": "Enabled",
  "profile-data": "Profile-Data",
  "licence": "Licence",
  "firstname": "First Name",
  "lastname": "Last Name",
  "organization": "Organization",
  "username": "Username",
  "userpassword": "New password",
  "repeatuserpassword": "Repeat password",
  "email-address": "E-Mail Address",
  "extensionnumber": "Extension number",
  "will-be-set-on-save": "Will be set after saving",
  "externalnumbers": "External numbers",
  "external-inbound-phone-number": "Inbound",
  "external-outbound-phone-number": "Outbound",
  "voicemail-settings": "Voicemail",
  "voicemail-active": "Voicemail active",
  "voicemail-password": "Voicemail password",
  "voicemail-language": "Voicemail language",
  "call-group-settings": "Pickup Group",
  "call-group": {
    "active": "Active",
    "groups": "Groups",
    "enter-groups": "Enter the user's pickup groups.",
    "deleteGroupConfirmationTitle": "Call Groups",
    "deleteGroupConfirmationMessage": "Do you really want to delete the Group \"{name}\"?",
    "not-assigned": "You are not assigned currently to any Call Group",
    "confirm": "Confirm",
    "no-confirmation": "No confirmation"
  },
  "call-forward-settings": "Call Forwarding",
  "phone-number": "Forwarding number",
  "at-busy": "At Busy",
  "no-answer": "No Answer",
  "not-registered": "Not registered",
  "call-forwarding": {
    "phone-number": "Forward all calls to this number.",
    "at-busy": "If enabled, the extension's active mailbox will be overwritten.",
    "no-answer": "If enabled, the extension's active mailbox will be overwritten.",
    "not-registered": "Call forwarding to new destination if original destination is unreachable before mailbox is activated."
  },
  "restriction-errors": {
    "too-short": "Has to contain at least {minimum} Characters",
    "unique-username": "Username {username} is already taken.",
    "unique-extension": "Extension {extension} is already taken.",
    "unique-inbound-number": "Inbound number {inboundNumber} is already taken.",
    "regex-errors": {
      "PHONE_NUMBER": "Has to be a phone number and start with +",
      "EXTENSION_NUMBER": "May contain only numbers and can't start with a 0",
      "EMAIL": "Invalid mail address",
      "DOMAIN_NUMBER_RESTRICTION": "Phone number is not allowed for this Account",
      "VOICEMAIL_PASSWORD": "Has to consist of 6 to 16 digits",
      "USERNAME": "Username has to consist of letters, digits, -, . or _.",

      "other": "Wrong format"
    },
    "excluded-value-list-errors": {
      "CALL_CENTER_QUEUE": "This number is already being used for a call center queue",
      "RING_GROUP": "This number is already being used for a call group",
      "TIME_CONDITION": "This number is already being used for a time condition",
      "DESTINATION": "This number is already being used for a destination",
      "IVR_MENU": "This number is already being used for an IVR menu",
      "EXTENSION": "This number is already used by another user",
      "other": "This number is already being used elsewhere"
    },
    "wrong-format": "Wrong format",
    "empty": "Can't be empty",
    "passwords-not-matching": "The passwords must match",
    "duplicate": "This value is already used in {user}",
    "at-least-one-target": "At least one target option has to be added",
    "unknown-error": "Validation error"
  },
  "secretary-function-settings": "Secretary settings",
  "secretary-function": {
    "active": "Active",
    "target": "My secretary"
  },
  "ringduration": "rang for {seconds} s",
  "callduration": "talked for {seconds} s",
  "missedcall": "Missed",
  "contactinfo": "Contact info",
  "enter-new-message": "Enter a new message",
  "unknown-contact": "Unknown Contact",
  "fromconferenceOptions": "from a conference without participants | from a conference with one participant | from a conference with {n} participants",
  "wtgcloudpure": "WTG Cloud PURE",
  "incomingcallfrom": "Incoming call from {0}",
  "incomingcalls": "No incoming calls | Incoming call | {n} incoming calls",
  "pickup": "Pickup",
  "hide": "Hide",
  "show_all": "Show all",
  "search-for-user": "User search",
  "clear-selection": "Clear all",
  "not-available": "No Number available",
  "min": "min.",
  "sec": "sec.",
  "today": "Today",
  "yesterday": "Yesterday",
  "break-comment": {
    "break-comment": "Break reason",
    "select-prompt": "Select break reason",
    "MEETING": "Meeting",
    "BACKOFFICE": "Backoffice",
    "BREAK": "Break",
    "SUPERVISOR": "Supervisor"
  },
  "answered": "Answered",
  "logged-out": "Logged Out",
  "available": "Available",
  "take-a-break": "Take a break",
  "choose-break-reason": "Please choose the reason for your break.",
  "select-break-reason": "Select break reason",
  "break": "Break",
  "break-reason": "Break reason",
  "in-call": "In call",
  "wrap-up": "Wrap up",
  "backdrop_you_are_set_to_break": "You have been put on pause.",
  "backdrop_supervisor_set_break": "Your supervisor has changed your status to Pause. You are not active and no longer receive calls from the queue. The reason for the pause is: Paused by the supervisor.",
  "backdrop_inactive_set_break": "Because you stopped answering calls, your status was set to pause. You are not active and no longer receive calls from the queue. The pause reason is: Paused due to inactivity.",
  "backdrop_return_to_active": "To become active again, you must change your status to Registered.",
  "park": "Park",
  "park-call": "Park call",
  "search-name": "Search",
  "edit": "Edit",
  "done": "Done",
  "all-read": "All read",
  "delete": "Delete",
  "no-one-waiting": "No one is waiting.",
  "everyone-happy": "Everyone is happy.",
  "call-reasons": {
    "no-reason": "No reason",
    "customer-data-changed": "Change customer data",
    "opening-time-inquiry": "Opening time inquiry",
    "banking-information": "Banking information",
    "order": "Order",
    "product-inquiry": "Product inquiry",
    "support": "Support"
  },
  "duration": "Duration",
  "search-for-contact": "Search for contact",
  "direct": "Direct",
  "groupname": "Group name",
  "to": "To",
  "participants": "Participants",
  "contact": "Contact",
  "no-number-available": "No number available",
  "type": "Type",
  "calltime": "Call time",
  "date": "Date",
  "time": "Time",
  "notice": "Notice",
  "waittime": "Waiting time",
  "Overview": "Overview",
  "Users": "User",
  "call-settings": {
    "label": "Telephony settings",
    "RingGroups": "Call Groups",
    "CallBroadcast": "Call Broadcast",
    "Blacklisting": "Blacklisting",
    "IVR": "IVR",
    "AnnouncementManagement": "Announcement Management",
    "OpenTimes": "Holidays / opening hours",
    "ParkingFunction": "Parking function",
    "Pickup": "Pickup",
    "SwitchBoardSettings": "Switchboard"
  },
  "devices-settings": {
    "label": "Devices",
    "Provisoning": "Provisioning",
    "Registration": "Registration"
  },
  "callcenter-settings": {
    "label": "Call center settings",
    "QueuesSettings": "Call Center Queues",
    "Supervisor": "Call Center Supervisor"
  },
  "softclient-settings": {
    "label": "Soft-Client Einstellungen",
    "Shortcuts": "Shortcuts",
    "MultiField": "Multi-Field"
  },
  "ivr": {
    "ivr": "IVR",
    "IVRs": "IVR Menu",
    "addIVR": "Add IVR",
    "editIVR": "Edit IVR",
    "deleteIVR": "Delete IVR",
    "cancel": "Cancel",
    "search": "Search for IVR",
    "save": "Save",
    "active": "Active",
    "name": "Name",
    "addName": "Add name",
    "extension": "Extension",
    "addExtension": "Add extension",
    "parentMenu": "Parent menu",
    "selectParentMenu": "Select parent menu",
    "language": "Language",
    "selectLanguage": "Select language",
    "description": "Description",
    "addDescription": "Add description",
    "greeting": "Greeting",
    "selectAnnouncement": "Select greeting",
    "long": "Long",
    "longGreetingDescription": "The long greeting is played when entering the menu.",
    "short": "Short",
    "shortGreetingDescription": "The short greeting is played when returning to the menu.",
    "options": "Options",
    "option": "Option",
    "target": "Target",
    "selectTarget": "Select target",
    "order": "Order",
    "defineCallOptions": "Define caller options for the IVR menu.",
    "timeout": "Timeout",
    "time": "Time",
    "enterTime": "Set timeout",
    "selectAction": "Select action",
    "enterTimeFieldDescription": "The number of milliseconds to wait after playing the greeting or the confirm macro.",
    "exitAction": "Exit action",
    "selectExitActionFieldDescription": "Select the exit action to be performed if the IVR exits.",
    "ringback": "Ring back",
    "ringbackFieldDescription": "Defines what the caller will hear while the destination is being called.",
    "callerPrefix": "Caller prefix",
    "callerPrefixFieldDescription": "Set a prefix on the caller ID name.",
    "selectPrefix": "Select prefix",
    "selectSound": "Select sound",
    "invalidOptionSound": "Invalid Sound",
    "invalidOptionSoundFieldDescription": "Played when and invalid option is chosen.",
    "soundOnMenuExit": "Exit menu sound",
    "soundOnMenuExitFieldDescription": "Played when leaving the menu.",
    "pin": "PIN",
    "setPin": "Set PIN",
    "pinFieldDescription": "Optional pin number to secure access to the menu.",
    "delayTimeBetweenInput": "Inter-digit timeout",
    "setDelayTimeBetweenInput": "Set Inter-digit timeout",
    "delayTimeBetweenInputFieldDescription": "The number of milliseconds to wait between digits.",
    "maxFailures": "Max Failures",
    "setMaxFailures": "Set max failures",
    "maxFailuresFieldDescription": "Maximum number of retries before exit.",
    "maximumTimeouts": "Max Timeouts",
    "setMaximumTimeouts": "Set max timeouts",
    "maximumTimeoutsFieldDescription": "Maximum number of timeouts before exiting.",
    "digitLength": "Digit Length",
    "setDigitLength": "Set digit length",
    "digitLengthFieldDescription": "Maximum number of digits allowed.",
    "recordingAnnouncement": "The announcements must be added via the WTG Master Admin. If you would like to add new audio files, please send them to: pure.tsc{'@'}wtg.com",
    "deleteIvrConfirmationTitle": "IVR Menu",
    "deleteIvrConfirmationMessage": "Do you really want to delete the IVR \"{name}\"?"
  },
  "pickupGroups": {
    "search": "Search for pickup groups",
    "members-number": "Number of group members",
    "addPickupGroup": "Add Pickup Group",
    "editPickupGroup": "Edit Pickup Group",
    "pickupGroupsHeader": "Pickup Groups",
    "numberOfMembers": "Number of members",
    "members": "Members",
    "notBlank": "The input may not be empty!",
    "noMembers": "The member list may not be empty!",
    "nameTaken": "This name is already taken!",
    "deleteGroupConfirmationTitle": "Pickup Groups",
    "deleteGroupConfirmationMessage": "Do you really want to delete the Pickup Group \"{name}\"?"
  },
  "call-center-queues": {
    "addQueueSuccess": "Successfully added queue.",
    "addQueueError": "Problem occurred while adding the queue",
    "editQueueSuccess": "Successfully edited queue.",
    "editQueueError": "Problem occurred while editing the queue",
    "removeQueueSuccess": "Queue was successfully deleted.",
    "removeQueueError": "Problem occurred while deleting the queue",
    "logged-queues": "Assigned Queues",
    "search": "Search for call center queues",
    "queues": "Queues",
    "call-center-queues": "Call Center Queues",
    "cancel": "Cancel",
    "save": "Save",
    "addQueue": "Add Queue",
    "editQueue": "Edit queue",
    "deleteQueue": "Delete queue",
    "name": "Name",
    "addName": "Add name",
    "extension": "Extension",
    "selectExtension": "Select extension",
    "greeting": "Greeting",
    "selectGreeting": "Select greeting",
    "strategy": "Strategy",
    "selectStrategy": "Select strategy",
    "description": "Description",
    "addDescription": "Add description",
    "thresholds": "Threshold",
    "value": "Value",
    "redStart": "Red from",
    "orangeStart": "Orange from",
    "numberOfWaiting": "Number of waiting callers",
    "loggedLessThan": "Logged in fewer than (in %)",
    "inBreakMoreThan": "On Break more than (in %)",
    "availableLessThan": "Available less than (in %)",
    "thresholdsSectionDescription": "Set thresholds for a visual alert.",
    "agents": "Agents",
    "tierLevel": "Tier Level",
    "tierPosition": "Tier Pos.",
    "addAgent": "Select agent",
    "level": "Level",
    "pos": "Pos.",
    "agentsSectionDescription": "Tiers assign agents to queues.",
    "supervisor": "Supervisor",
    "addSupervisor": "Select Supervisor",
    "supervisorSectionDescription": "Select one or more supervisors for this queue.",
    "musicOnHold": "Music on hold",
    "selectMusicOnHold": "Select music on hold",
    "order": "Order",
    "selectOrder": "Select order",
    "recording": "Record",
    "recordingToggleDescription": "Save the recording.",
    "timeBaseValue": "Time base score",
    "timeBaseValueFieldDescription": "Selection of whether the time the caller spent in the system is counted as the waiting time.",
    "timeout": "Timeout",
    "selectWaitingTime": "Set max wait time",
    "maxWaitingTime": "Max wait time",
    "maxWaitingTimeFieldDescription": "Wating time in seconds before the action is performed.",
    "maxWaitingTimeNoAgent": "Max wait time with no agent",
    "maxWaitingTimeNoAgentFieldDescription": "The time a caller spends in the queue before the selected action is scheduled to be performed.",
    "maxWaitingTimeNoAvailableAgent": "Max. wait time with no available agent",
    "maxWaitingTimeNoAvailableAgentFieldDescription": "The time a caller spends in the queue before the selected action is scheduled to be performed.",
    "timeoutAction": "Timeout action",
    "timeoutActionFieldDescription": "Set the action to perform when the max wait time is reached.",
    "selectAction": "Select timeout action",
    "tierRules": "Tier rules",
    "tierRulesToggleDescription": "Specify whether the tier rules should apply to the queue.",
    "tierRuleWaitMultiply": "Extension of the tier rule waiting time",
    "tierRuleWaitMultiplyToggleDescription": "Extension of the waiting time if the caller waits until the next level.",
    "tierRuleNoAgentNoWait": "No agent, no wait time",
    "tierRuleNoAgentNoWaitToggleDescription": "If no agent is available, the caller goes directly to the next tier level.",
    "discardCall": "Abandon caller",
    "discardCallFieldDescription": "Specify after how many seconds the call should be discarded.",
    "selectTime": "Set time",
    "abandonedResumeAllowed": "Resume abandoned caller",
    "abandonedResumeAllowedToggleDescription": "Allow caller to resume his position in the queue.",
    "callerPrefix": "Caller prefix",
    "setPrefix": "Set prefix",
    "callerOutboundName": "Outbound name",
    "callerOutboundNameDescription": "Enter the external caller outbound name.",
    "callerOutboundNumber": "Outbound number",
    "callerOutboundNumberDescription": "Enter the external caller outbound number.",
    "callerPrefixFieldDescription": "Specify a prefix that should be displayed in front of the phone number for incoming callers.",
    "announceSound": "Announce sound",
    "announceSoundFieldDescription": "The waiting message to be played to the caller at recurring intervals.",
    "selectSound": "Select sound",
    "announceFrequency": "Announce frequency",
    "announceFrequencyFieldDescription": "After how many seconds the waiting message should be played repeatedly.",
    "ring-all": "Ring all",
    "longest-idle-agent": "Longest idle agent",
    "round-robin": "Round robin",
    "top-down": "Top down",
    "agent-with-least-talk-time": "Agent with least talk time",
    "agent-with-fewest-calls": "Agent with fewest calls",
    "sequentially-by-agent-order": "Sequentially by agent order",
    "sequentially-by-next-agent-order": "Sequentially by next agent order",
    "random": "Random",
    "deleteQueueConfirmationTitle": "Call Center Queues",
    "deleteQueueConfirmationMessage": "Do you really want to delete the Queue \"{name}\"?",
    "incomingCalls": "Incoming calls (Queue)"
  },
  "ring-groups": {
    "search": "Search for call groups",
    "active": "Active",
    "callGroups": "Call Groups",
    "add": "Add",
    "deleteCallGroup": "Delete Call Group",
    "name": "Name",
    "addName": "Add name",
    "groups": "Groups",
    "extension": "Extension",
    "addExtension": "Add extension",
    "greeting": "Greeting",
    "selectGreeting": "Select greeting",
    "strategy": "Strategy",
    "selectStrategy": "Select strategy",
    "description": "Description",
    "addDescription": "Add description",
    "save": "Save",
    "cancel": "Cancel",
    "addCallGroup": "Add Call Group",
    "editCallGroup": "Edit Call Group",
    "delay": "Delay",
    "timeLimit": "Time limit",
    "accept": "Accept",
    "targetByTimeLimit": "Target by time limit",
    "selectTarget": "Select target",
    "addTarget": "Add target",
    "targetsSectionDescription": "Add targets and parameters to the ring group.",
    "callTimeout": "Call timeout",
    "chooseCallTimeout": "Select call timeout",
    "callerIdName": "Caller ID name",
    "selectCallerIdName": "Select caller ID name",
    "callerIdNameFieldDescription": "Set caller ID name for outgoing external calls.",
    "callerIdNumber": "Caller ID Number",
    "selectCallerIdNumber": "Select caller ID number",
    "callerIdNumberFieldDescription": "Set the caller ID number for outgoing external calls.",
    "busyTone": "Ring back tone",
    "busyToneFieldDescription": "Set what the caller hears while the target is being called.",
    "selectSound": "Select sound",
    "recall": "Callback",
    "recallFieldDescription": "Specifies what the caller hears while the target is being called.",
    "groupMembers": "Group members",
    "selectContact": "Select contact",
    "forwarding": "Call forwarding",
    "forwardingFieldDescription": "Select whether call forwarding should be applied within the group.",
    "followMe": "Follow Me",
    "followMeFieldDescription": "Select the \"Follow me\" option for a ring group destination.",
    "missedCalls": "Missed calls",
    "missedCallsFieldDescription": "Choose whether you want to notify someone via email.",
    "emailAddress": "E-mail address",
    "target": "Target",
    "targets": "Targets",
    "typeOfNotification": "Type of notification",
    "callGroupForwarding": "Collective call group forwarding",
    "callGroupForwardingFieldDescription": "Forwarding of a called call group to another destination.",
    "selectNumber": "Select number",
    "feesForwarding": "Fees forwarding",
    "selectFeesForwarding": "Select fees forwarding",
    "callGroupForward": "Call group forward",
    "callGroupForwardFieldDescription": "Redirection of the entire call group to a selected destination.",
    "simultaneous": "Simultaneous",
    "sequence": "Sequence",
    "enterprise": "Enterprise",
    "rollover": "Rollover",
    "random": "Random"
  }
}
