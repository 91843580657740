import { defineStore } from 'pinia';
import { computed, readonly, ref, watch } from 'vue';
import { parseDataFromBrowserStorage, saveToBrowserStorage } from '@/utils/storage';
import { LoadingStatus } from '@/constants/loadingStatus';
import { BrowserStorageKey } from '@/constants/browserStorage';
import type { Contact } from '@/data/contacts/Contact';

export const isOutlookContact = (contact: Contact | OutlookContactData): contact is OutlookContactData => {
  return contact.isOutlookContact;
};
export interface OutlookContactData extends Contact {
  isFavorite: boolean;
}
// todo clean commented code after regression test
const restoreOutlookContactsFromDb = () => {
  return (parseDataFromBrowserStorage(BrowserStorageKey.OUTLOOK_CONTACTS) || {}) as Record<string, OutlookContactData>;

  // const outlookContacts: OutlookContact[] | null = getDataFromBrowserStorage(BrowserStorageKeys.OUTLOOK_CONTACTS)
  // __outlookContacts.value = outlookContacts?.reduce((acc, currentContact) => {
  //   acc[currentContact.uuid] = currentContact
  //   return acc
  // }, {} as Record<string, OutlookContact>) || {}

  // return new Promise((resolve) => {
  //   if (this.db === null) {
  //     return resolve()
  //   }
  //   const transaction = this.db.transaction(['OutlookContacts'], 'readonly')
  //   const objectstore = transaction.objectStore('OutlookContacts')
  //
  //   const restoredObjects: Record<string, OutlookContact> = {}
  //
  //   const request = objectstore.openCursor()
  //   request.onsuccess = () => {
  //     const cursor = request.result
  //     if (cursor) {
  //       const restoredData = OutlookContact.restoreFromExport(this.apiSession, cursor.value)
  //       restoredObjects[restoredData.uuid] = restoredData
  //       cursor.continue()
  //     } else {
  //       console.log('No more entries!')
  //       if (this.outlookContacts && Object.keys(this.outlookContacts).length > 0) {
  //         console.log('Already got objects from outlook, not restoring.')
  //       } else {
  //         this.outlookContacts = restoredObjects
  //         console.log('Db restoration complete.')
  //       }
  //     }
  //   }
  //
  //   transaction.oncomplete = () => {
  //     this.apiSession.refreshPhoneNumberCache()
  //     resolve()
  //   }
  // })
};
export const useOutlookContactsStore = defineStore('outlookContacts', () => {
  const __outlookContacts = ref<Record<string, OutlookContactData>>(restoreOutlookContactsFromDb());
  const __importOutlookContactsStatus = ref<LoadingStatus>(LoadingStatus.IDLE);

  watch(__outlookContacts, () => {
    saveOutlookContactsToDb();
  });

  const _importOutlookContacts = async () => {
    const outlookContactsString = await window.wtgPhoneApi.importOutlookContacts();
    if (outlookContactsString) {
      const contactData: OutlookContactData[] = JSON.parse(outlookContactsString);
      const newOutlookContacts: Record<string, OutlookContactData> = {};
      for (const data of contactData) {
        const previousIsFavorite = data.uuid in __outlookContacts.value && __outlookContacts.value[data.uuid].isFavorite;
        if (previousIsFavorite) {
          data.isFavorite = true;
        }

        newOutlookContacts[data.uuid] = { ...data, isOutlookContact: true };
      }
      __outlookContacts.value = newOutlookContacts;
    }
  };

  const importOutlookContacts = async () => {
    __importOutlookContactsStatus.value = LoadingStatus.RUNNING;
    try {
      await _importOutlookContacts();
      __importOutlookContactsStatus.value = LoadingStatus.SUCCESS;
    } catch (e) {
      console.error('Importing outlook contacts failed:', e);
      __importOutlookContactsStatus.value = LoadingStatus.FAILURE;
    }
  };

  const saveOutlookContactsToDb = () => {
    saveToBrowserStorage(BrowserStorageKey.OUTLOOK_CONTACTS, __outlookContacts.value);
    // if (this.db !== null) {
    //   const transaction = this.db.transaction(['OutlookContacts'], 'readwrite')
    //   const objectstore = transaction.objectStore('OutlookContacts')
    //   objectstore.clear()
    //   for (const o of Object.values(this.outlookContacts)) {
    //     objectstore.add(o.export())
    //   }
    //   transaction.oncomplete = (event) => {
    //     console.log('Persisting OutlookContacts completed:', event)
    //   }
    // }
  };

  const toggleFavorites = (uuid: string) => {
    __outlookContacts.value[uuid].isFavorite = !__outlookContacts.value[uuid].isFavorite;
    __outlookContacts.value = { ...__outlookContacts.value };
  };

  const removeOutlookContacts = () => {
    __outlookContacts.value = {};
    __importOutlookContactsStatus.value = LoadingStatus.IDLE;
  };

  const _numberOfOutlookContacts = computed(() => {
    return Object.keys(__outlookContacts.value || {}).length;
  });

  const _outlookContacts = computed(() => {
    return Object.values(__outlookContacts.value || {});
  });

  const _outlookImportStatus = readonly(__importOutlookContactsStatus);

  return {
    importOutlookContacts,
    restoreOutlookContactsFromDb,
    removeOutlookContacts,
    toggleFavorites,
    _numberOfOutlookContacts,
    _outlookImportStatus,
    _outlookContacts,
  };
});
