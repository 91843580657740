import type { UseQueryReturnType } from '@tanstack/vue-query';
import { httpGet } from '@/api/http';
import { CONTACT_AVATAR, CONTACTS } from '@/constants/endpoint';
import type { ContactUrl } from '@/data/contacts/ContactUrl';
import type { ContactPhone } from '@/data/contacts/ContactPhone';
import type { ContactAttachment } from '@/data/contacts/ContactAttachment';
import type { ContactAddress } from '@/data/contacts/ContactAddress';
import type { ContactEmail } from '@/data/contacts/ContactEmail';
import { byContactUuidMapping, useUsers } from '@/api/useUsers';
import type { Contact, ContactExtended } from '@/data/contacts/Contact';
import type { User } from '@/data/User';
import type { AttachmentDTO } from '@/data/Attachment';
import { useFavorites } from '@/api/useFavorites';
import { computed } from 'vue';
import type { AxiosError } from 'axios';
import { useAuthenticatedQuery } from '@/utils/query';
import { isOutlookContact, useOutlookContactsStore } from '@/stores/useOutlookContactsStore';
import { isElectron } from '@/util';

export const CONTACTS_QUERY_KEY = 'contacts';

export const contactsQuery = {
  queryKey: [CONTACTS_QUERY_KEY],
  queryFn: () =>
    httpGet<{ contacts: ContactDTO[] }>(CONTACTS)
      .then((r) => r.contacts)
      .then((r) => r.map(mapContactDTOToContactData)),
  refetchInterval: 600_000,
  refetchOnMount: false,
};
// need to explicitly type due to issue
const _useContacts = (): UseQueryReturnType<Contact[] | undefined, AxiosError<unknown, any>> => {
  return useAuthenticatedQuery(contactsQuery);
};

export const useContacts = () => {
  const contacts = _useContacts();
  const favorites = useFavorites();
  const { data: users } = useUsers(byContactUuidMapping);
  const $outlookContactsStore = useOutlookContactsStore();

  const mergedContacts = computed(() => {
    if (!isElectron()) return contacts.data.value || [];
    return [...(contacts.data.value || []), ...$outlookContactsStore._outlookContacts];
  });

  const mappedContacts = computed(() => {
    return mergedContacts.value.map((contactData) => {
      const user = contactData.type === 'user' ? users.value?.get(contactData.uuid) : undefined;

      const isFavorite = isOutlookContact(contactData) ? !!contactData.isFavorite : !!favorites.data?.value?.get(contactData.uuid);

      return {
        uuid: contactData.uuid,
        url: contactData.url,
        type: contactData.type,
        title: contactData.title,
        timeZone: contactData.timeZone,
        role: contactData.role,
        organization: contactData.organization,
        note: contactData.note,
        nickname: contactData.nickname,
        namePrefix: contactData.namePrefix,
        nameGiven: contactData.nameGiven,
        nameMiddle: contactData.nameMiddle,
        nameFamily: contactData.nameFamily,
        nameSuffix: contactData.nameSuffix,
        category: contactData.category,
        phones: contactData.phones,
        attachments: contactData.attachments,
        addresses: contactData.addresses,
        emails: contactData.emails,
        urls: contactData.urls,
        isOutlookContact: contactData.isOutlookContact,
        // new properties
        isFavorite,
        extension: user?.extension?.extension,
        phoneNumber: getPhoneNumber(contactData, user),
        name: getName(contactData, user),
        initials: getInitials(contactData, user),
        user,
        source: 'Api',
        profilePicture: getProfilePictureURL(contactData),
      } as ContactExtended;
    });
  });
  return {
    data: mappedContacts,
    isLoading: contacts.isLoading,
    isError: contacts.isError,
    refetch: contacts.refetch,
  };
};

const getPhoneNumber = (contact: Contact, user?: User) => {
  if (user?.extension?.extension) {
    return user.extension.extension;
  }
  // is it possible that the only number is not primary?
  return contact.phones.find((phone) => phone.primary)?.number || contact.phones[0]?.number;
};

const getName = (contact: Contact, user?: User): string => {
  const nameParts = [contact.namePrefix, contact.nameGiven, contact.nameMiddle, contact.nameFamily, contact.nameSuffix];
  const fullName = nameParts.filter((part) => part).join(' ');

  return fullName || contact.nickname || user?.username || '?';
};

const getInitials = (contact: Contact, user?: User): string => {
  // Get non-empty name parts and map to their first letters
  const initials = [contact.nameGiven, contact.nameMiddle, contact.nameFamily]
    .filter((namePart): namePart is string => !!namePart) // Type narrowing
    .map((namePart) => namePart[0]);

  // If no initials were found, return the first letter of the username or '?'
  if (initials.length === 0) {
    return user?.username?.[0] || '?';
  }

  // Combine the first and last initials
  const firstInitial = initials[0];
  const lastInitial = initials.length > 1 ? initials[initials.length - 1] : '';

  return firstInitial + lastInitial;
};

const getProfilePictureURL = (contactData: Contact): string | null => {
  let uuid = contactData.attachments.find((a) => a.primary)?.uuid || contactData.attachments[0];
  if (typeof uuid === 'object') uuid = uuid.uuid;
  return uuid ? `${CONTACT_AVATAR}?contact_attachment_uuid=${uuid}&get=content` : null;
};

function mapContactDTOToContactData(contactDTO: ContactDTO): Contact {
  return {
    uuid: contactDTO.contact_uuid,
    url: contactDTO.contact_url,
    type: contactDTO.contact_type,
    title: contactDTO.contact_title,
    timeZone: contactDTO.contact_time_zone,
    role: contactDTO.contact_role,
    organization: contactDTO.contact_organization,
    note: contactDTO.contact_note,
    nickname: contactDTO.contact_nickname,
    namePrefix: contactDTO.contact_name_prefix,
    nameGiven: contactDTO.contact_name_given,
    nameMiddle: contactDTO.contact_name_middle,
    nameFamily: contactDTO.contact_name_family,
    nameSuffix: contactDTO.contact_name_suffix,
    category: contactDTO.contact_category,
    phones: contactDTO.phones.map(mapPhoneDTOToContactPhone),
    attachments: contactDTO.attachments.map(mapAttachmentDTOToContactAttachment),
    addresses: contactDTO.addresses.map(mapAddressDTOToContactAddress),
    emails: contactDTO.emails.map(mapEmailDTOToContactEmail),
    urls: contactDTO.urls,
    isOutlookContact: false,
  };
}

function mapPhoneDTOToContactPhone(phoneDTO: PhoneDTO): ContactPhone {
  return {
    uuid: phoneDTO.contact_phone_uuid,
    description: phoneDTO.phone_description,
    extension: phoneDTO.phone_extension,
    label: phoneDTO.phone_label,
    number: phoneDTO.phone_number,
    primary: phoneDTO.phone_primary,
    speedDial: phoneDTO.phone_speed_dial,
    typeFax: phoneDTO.phone_type_fax,
    typeText: phoneDTO.phone_type_text,
    typeVideo: phoneDTO.phone_type_video,
    typeVoice: phoneDTO.phone_type_voice,
  };
}

function mapAttachmentDTOToContactAttachment(attachmentDTO: AttachmentDTO): ContactAttachment {
  return {
    uuid: attachmentDTO.contact_attachment_uuid,
    filename: attachmentDTO.attachment_filename,
    primary: attachmentDTO.attachment_primary,
    uploadedDate: attachmentDTO.attachment_uploaded_date,
    uploadedUserUuid: attachmentDTO.attachment_uploaded_user_uuid,
  };
}

function mapAddressDTOToContactAddress(addressDTO: AddressDTO): ContactAddress {
  return {
    uuid: addressDTO.contact_address_uuid,
    community: addressDTO.address_community,
    country: addressDTO.address_country,
    description: addressDTO.address_description,
    extended: addressDTO.address_extended,
    label: addressDTO.address_label,
    latitude: addressDTO.address_latitude,
    longitude: addressDTO.address_longitude,
    locality: addressDTO.address_locality,
    postalCode: addressDTO.address_postal_code,
    primary: addressDTO.address_primary === '1',
    region: addressDTO.address_region,
    street: addressDTO.address_street,
    type: addressDTO.address_type,
  };
}

function mapEmailDTOToContactEmail(emailDTO: EmailDTO): ContactEmail {
  return {
    uuid: emailDTO.contact_email_uuid,
    address: emailDTO.email_address,
    description: emailDTO.email_description,
    label: emailDTO.email_label,
    primary: emailDTO.email_primary,
  };
}

export interface ContactDTO {
  contact_uuid: string;
  domain_uuid: string;
  contact_type: string;
  contact_organization: string;
  contact_name_prefix: string | null;
  contact_name_given: string;
  contact_name_middle: string | null;
  contact_name_family: string;
  contact_name_suffix: string | null;
  contact_nickname: string;
  contact_title: string | null;
  contact_role: string | null;
  contact_category: string | null;
  contact_url: string | null;
  contact_time_zone: string | null;
  contact_note: string | null;
  last_mod_date: string;
  user_uuid: string;
  phones: PhoneDTO[];
  addresses: AddressDTO[];
  emails: EmailDTO[];
  urls: ContactUrl[];
  attachments: AttachmentDTO[];
}

export interface PhoneDTO {
  contact_phone_uuid: string;
  contact_uuid: string;
  phone_type_voice: boolean;
  phone_type_fax: boolean;
  phone_type_video: boolean;
  phone_type_text: boolean;
  phone_label: string;
  phone_primary: boolean;
  phone_number: string;
  phone_extension: string | null;
  phone_speed_dial: string | null;
  phone_description: string | null;
}

export interface EmailDTO {
  contact_email_uuid: string;
  contact_uuid: string;
  email_label?: string;
  email_primary: boolean;
  email_address: string;
  email_description?: string;
}

export interface AddressDTO {
  contact_address_uuid: string;
  contact_uuid: string;
  address_type: string;
  address_label: string;
  address_primary?: '0' | '1';
  address_street: string;
  address_extended?: string;
  address_community?: string;
  address_locality: string;
  address_region?: string;
  address_postal_code: string;
  address_country: string;
  address_latitude?: string;
  address_longitude?: string;
  address_description?: string;
}
